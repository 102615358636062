import React from 'react'
import UserAuthRotes from './userAuthRoutes'
import UserPrivateRoutes from './userPrivateRoutes'


const UserRoutes = () => {
    const Token =localStorage.getItem('token')
  return (

   <>
  {
    Token?  <UserPrivateRoutes/> :<UserAuthRotes/>
  }
   </>
   
  )
}

export default UserRoutes