import React from 'react';
import { Form, Input, Button, Typography, notification } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const SignUpPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate()

  const onFinish = (values) => {
   
    notification.success({
      message: 'Sign Up Successful',
      description: 'You have successfully signed up!',
    });

    // Reset form
    form.resetFields();
    navigate("/login")
  };

  return (
   <div className='signup-page'>
     <div style={{ minWidth: '480px', margin: 'auto', padding: '32px', background: '#fff', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>Sign Up</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true, message: 'Please enter your username' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter a valid email' },
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[{ required: true, message: 'Please enter your phone number' }]}
        >
          <Input prefix={<PhoneOutlined />} placeholder="Phone Number" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', borderRadius: '4px' }}>
            Sign Up
          </Button>
        </Form.Item>
      </Form>
      <div className="register-link" onClick={()=>navigate("/login")}>
            Don't have an account? 
            </div>
    </div>
   </div>
  );
};

export default SignUpPage;
