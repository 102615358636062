import React from 'react'
import AdminAuthRotes from './adminAuthRoutes'
import AdminPrivateRoutes from './adminPrivateRoutes'


const AdminRoutes = () => {
    const Token =localStorage.getItem('token')
  return (

   <>
  {
    Token?  <AdminPrivateRoutes/> :<AdminAuthRotes/>
  }
   </>
   
  )
}

export default AdminRoutes