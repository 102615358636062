import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { UserOutlined, CalendarOutlined, FileTextOutlined, ShopOutlined } from '@ant-design/icons';


const Dashboard = () => {
  return (
   <>
     <div style={{ padding: '24px', background: '#fff' }}>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Users"
              value={3}
              prefix={<UserOutlined />}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Events This Month"
              value={2}
              prefix={<CalendarOutlined />}
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Reports Generated"
              value={5}
              prefix={<FileTextOutlined />}
              valueStyle={{ color: '#1890ff' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Products Sold"
              value={0}
              prefix={<ShopOutlined />}
              valueStyle={{ color: '#faad14' }}
            />
          </Card>
        </Col>
      </Row>
    </div>

   </>
  );
};

export default Dashboard;
