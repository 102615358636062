import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { UserOutlined, CalendarOutlined, FileTextOutlined, ShopOutlined } from '@ant-design/icons';


const UserDashboard = () => {
  return (
   <>
     <div className="dashboard-main" style={{background: '#fff' }}>
       <div className='ctm-dashed-card '>
          <span className='ctm-dashed-icons'>
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23" cy="23" r="23" fill="#E27140" fill-opacity="0.1"/>
                <circle cx="23" cy="23" r="19" fill="#E27140"/>
                <path d="M15.9948 31H31.0052C32.5401 31 33.497 29.3307 32.7295 28.0013L25.2243 14.9971C24.4568 13.6676 22.5432 13.6676 21.7757 14.9971L14.2705 28.0013C13.503 29.3307 14.4599 31 15.9948 31ZM23.5 24.0031C22.9518 24.0031 22.5033 23.5533 22.5033 23.0035V21.0044C22.5033 20.4547 22.9518 20.0049 23.5 20.0049C24.0482 20.0049 24.4967 20.4547 24.4967 21.0044V23.0035C24.4967 23.5533 24.0482 24.0031 23.5 24.0031ZM24.4967 28.0013H22.5033V26.0022H24.4967V28.0013Z" fill="white"/>
            </svg>
          </span>
          <div className='ctm-dashed-content'>
            <span>Action to get more leads</span>
            <div className='ctm-dashed-btns'>
                <button className='ctm-solid-btn'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.95459 11.9326V6.06783C6.95471 5.99446 6.97457 5.92246 7.01208 5.8594C7.04959 5.79633 7.10337 5.74451 7.16779 5.70938C7.2322 5.67424 7.30489 5.65707 7.37822 5.65968C7.45155 5.66229 7.52283 5.68457 7.58459 5.7242L12.1468 8.65574C12.2045 8.69276 12.2521 8.7437 12.285 8.80389C12.3179 8.86409 12.3352 8.93159 12.3352 9.0002C12.3352 9.0688 12.3179 9.13631 12.285 9.1965C12.2521 9.25669 12.2045 9.30764 12.1468 9.34465L7.58459 12.277C7.52283 12.3166 7.45155 12.3389 7.37822 12.3415C7.30489 12.3441 7.2322 12.327 7.16779 12.2918C7.10337 12.2567 7.04959 12.2049 7.01208 12.1418C6.97457 12.0788 6.95471 12.0068 6.95459 11.9334V11.9326Z" fill="white"/>
                      <path d="M0 9C0 4.02955 4.02955 0 9 0C13.9705 0 18 4.02955 18 9C18 13.9705 13.9705 18 9 18C4.02955 18 0 13.9705 0 9ZM9 1.22727C6.93854 1.22727 4.96152 2.04618 3.50385 3.50385C2.04618 4.96152 1.22727 6.93854 1.22727 9C1.22727 11.0615 2.04618 13.0385 3.50385 14.4961C4.96152 15.9538 6.93854 16.7727 9 16.7727C11.0615 16.7727 13.0385 15.9538 14.4961 14.4961C15.9538 13.0385 16.7727 11.0615 16.7727 9C16.7727 6.93854 15.9538 4.96152 14.4961 3.50385C13.0385 2.04618 11.0615 1.22727 9 1.22727Z" fill="white"/>
                  </svg> How to Setup
                </button>
                <button className='ctm-solid-btn'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.95459 11.9326V6.06783C6.95471 5.99446 6.97457 5.92246 7.01208 5.8594C7.04959 5.79633 7.10337 5.74451 7.16779 5.70938C7.2322 5.67424 7.30489 5.65707 7.37822 5.65968C7.45155 5.66229 7.52283 5.68457 7.58459 5.7242L12.1468 8.65574C12.2045 8.69276 12.2521 8.7437 12.285 8.80389C12.3179 8.86409 12.3352 8.93159 12.3352 9.0002C12.3352 9.0688 12.3179 9.13631 12.285 9.1965C12.2521 9.25669 12.2045 9.30764 12.1468 9.34465L7.58459 12.277C7.52283 12.3166 7.45155 12.3389 7.37822 12.3415C7.30489 12.3441 7.2322 12.327 7.16779 12.2918C7.10337 12.2567 7.04959 12.2049 7.01208 12.1418C6.97457 12.0788 6.95471 12.0068 6.95459 11.9334V11.9326Z" fill="white"/>
                      <path d="M0 9C0 4.02955 4.02955 0 9 0C13.9705 0 18 4.02955 18 9C18 13.9705 13.9705 18 9 18C4.02955 18 0 13.9705 0 9ZM9 1.22727C6.93854 1.22727 4.96152 2.04618 3.50385 3.50385C2.04618 4.96152 1.22727 6.93854 1.22727 9C1.22727 11.0615 2.04618 13.0385 3.50385 14.4961C4.96152 15.9538 6.93854 16.7727 9 16.7727C11.0615 16.7727 13.0385 15.9538 14.4961 14.4961C15.9538 13.0385 16.7727 11.0615 16.7727 9C16.7727 6.93854 15.9538 4.96152 14.4961 3.50385C13.0385 2.04618 11.0615 1.22727 9 1.22727Z" fill="white"/>
                  </svg> How to find best group?
                </button>
            </div>
          </div>
          <span className='ctm-dashed-close'>
            <button className='ctm-dashed-close-btn'>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 1L1 13M1 1L13 13" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </span>
       </div>
    </div>
    <h2 className='fs-24'>Leads</h2>
    <div className='dashboard-Leads-wraper'>
        <div className='dash-leads-items'>
            <span className='dash-leads-title'>142 Leads</span>
            <div className='dash-leads-iconText'>
              <span className='dash-leads-icons'>
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16.5" cy="16.5" r="16.5" fill="#515DEF"/>
                    <path d="M18.6155 21.942H21.6374L22.8462 16.7382H18.6155V14.1362C18.6155 12.7963 18.6155 11.5343 21.0331 11.5343H22.8462V7.16309C22.4522 7.10715 20.9642 6.98096 19.3927 6.98096C16.1109 6.98096 13.7803 9.13665 13.7803 13.0955V16.7382H10.1539V21.942H13.7803V33.0002H18.6155V21.942Z" fill="white"/>
                </svg> 
              </span> Facebook Group</div>
        </div>
    </div>
   </>
  );
};

export default UserDashboard;
