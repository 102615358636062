import { Button, Input, message, Popconfirm, Table } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { deleteFriend, getFriendsList } from "../../../../services/userApiCalls";
import "./friends.css"

const Friends = () => {
  const [friendsList, setFriendsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: "Friend",
      dataIndex: "Friend",
      key: "Friend",
      sorter: (a,b ) => a.name.localeCompare(b.name),
      render: (text ,record) => (
        <div className="friends-wraper">
          <img src={record?.profile} />
          <span className="friends-wraper-name">{record?.name || '-'}</span>
          <a class="#!">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="17.5"
                fill="#2C73FF"
                fill-opacity="0.1"
                stroke="#E0E1E3"
              ></circle>
              <path
                d="M19.6667 19.075H22.0476L23 14.875H19.6667V12.775C19.6667 11.6935 19.6667 10.675 21.5714 10.675H23V7.147C22.6895 7.10185 21.5171 7 20.279 7C17.6933 7 15.8571 8.73985 15.8571 11.935V14.875H13V19.075H15.8571V28H19.6667V19.075Z"
                fill="#2C73FF"
              ></path>
            </svg>
          </a>
        </div>
      ),
    },
    {
      title: "Mutual Friends",
      dataIndex: "mutual_count",
      key: "mutual_count",
      sorter: (a, b) =>  a?.mutual_count - b?.mutual_count,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => a.gender.localeCompare(b.gender),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Popconfirm
            title="Are you sure you want to delete this friend?"
            onConfirm={() => handleDeleteUser(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const handleDeleteUser = async(key) => {
    const params ={
      id: key
    }
    try {
      const response = await deleteFriend(params);
      if (response?.status === 200) {
        message.success({
          content: 'Friend deleted successfully',
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
          
        })
        fetchFriends()
     
      }else{
        message.success({
            content: 'OOPS something went wrong',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
            
          })
      }
    } catch (error) {
        message.error({
            content: 'OOPS something went wrong',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
            
          })
    }
 
  };

  const fetchFriends = async () => {
    setIsLoading(true)
    const params = {
      type: "facebook",
      page: "1",
      limit: "10",
    };
    try {
      const response = await getFriendsList(params);
      if (response.status === 200) {
        console.log(response?.data?.data);
        setFriendsList(response?.data?.data ?? []);
      }
    setIsLoading(false)

    } catch (error) {
        setIsLoading(false)
        message.error({
            content: 'OOPS something went wrong',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
            
          })
    }
  };

  const filteredUsers = friendsList?.filter((user) =>
    Object.keys(user).some((key) =>
      user[key]?.toString()?.toLowerCase()?.includes(searchText)
    )
  );

  useEffect(() => {
    fetchFriends();
  }, []);
  return (
    <div className="frnds_page">
     <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
     <div className="frnds-title">
        <Title level={1}>Friends</Title>
      </div>
      <div className="frnds-Search">
        <Input
          placeholder="Search for friends"
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 200 }}
        />
      </div>
     </div>

      <Table
        columns={columns}
        dataSource={filteredUsers}
        pagination={{ pageSize: 10 }}
        rowKey="key"
        loading={isLoading}
      />
    </div>
  );
};

export default Friends;
