import React, { useEffect, useState } from 'react'
import { addGrpReq, getGrpReq } from '../../../../services/userApiCalls';
import "./groupComp.css"
import { message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import CheckableTag from 'antd/es/tag/CheckableTag';

const GroupSendRequest = ({grpId}) => {
  const [type, setType] = useState("add");
  const [time,setTime]=useState("1-3")
  const [requests,setRequests]=useState("5")
  const [loading, setLoading] = useState(false);
  const [keyWords,setKeyWords]=useState({
    negative:[],
    positive:[]
})
const [currentNegative, setCurrentNegative] = useState('');
const [currentPositive, setCurrentPositive] = useState('');
const navigate = useNavigate()

const  handleKeyDown = (target,isPos)=>{
  if(target.keyCode === 13){
      if (isPos) {
          currentPositive &&   setKeyWords((prev)=>({...prev,positive:[...prev.positive,currentPositive]}))
          setCurrentPositive('')
      }else{
          currentNegative &&    setKeyWords((prev)=>({...prev,negative:[...prev.negative,currentNegative]}))
          setCurrentNegative('')
      }
     
  }
 }


 const removeKeyWord = (isPos,num)=>{
  if(isPos){
      setKeyWords((prev)=>({...prev,positive:prev.positive.filter((_,indx)=>indx!==num)}))
  }else{
      setKeyWords((prev)=>({...prev,negative:prev.negative.filter((_,indx)=>indx!==num)}))
  }
 }

 const clearStates = ()=>{
  setTime("1-3")
  setRequests("5")
  setType("add")
  setKeyWords({
    negative:[],
    positive:[]
})
}

 const handleSubmit=()=>{
  const params ={
    requests,
    interval:time,
    negative_keywords: keyWords.negative,
    positive_keywords: keyWords.positive,
    type,
    group_id:`${grpId}`
}

 if(!time || keyWords.negative.length === 0 || keyWords.positive.length === 0 || !requests  ){
  message.error({
      content:"Please fill all the required fields",
      style: {
        position: 'absolute',
        right: '0px',
        top: '0px',
      }
    })
  return;
 }

 setLoading(true)

 addGrpReq(params).then((res)=>{
    setLoading(false)
    navigate('/groups')
      message.success({
        content:"Response Added successfully",
        style: {
          position: 'absolute',
          right: '0px',
          top: '0px',
        }
      })
      clearStates()
  }).catch((err)=>{
    message.error({
      content:"oops something went wrong",
      style: {
        position: 'absolute',
        right: '0px',
        top: '0px',
      }
    })
      setLoading(false)
  })
 }


 const fetchData = ()=>{
  setLoading(true)
  getGrpReq({group_id:`${grpId}`}).then((res)=>{
    if (res?.data?.data) {
      console.log(res?.data?.data)
      setRequests(`${res?.data?.data?.requests}`)
       setKeyWords({
         negative:res?.data?.data?.negative_keywords,
         positive:res?.data?.data?.positive_keywords
       })
       setTime(`${res?.data?.data?.interval}`)
      setType("update")
    }else{
      setType("add")
    }
    setLoading(false)
   
  }).catch((err)=>{
    setLoading(false)
  })
   
 }

  useEffect(() => {
  fetchData()
  if (grpId) {
    fetchData()
  }
 return ()=>{
    setKeyWords({negative:[],
      positive:[]})
    setTime("1-3")
    setRequests("5")
    setType("add")
  }
  }, [grpId])
  
  return (
    <>
		<div class="sen-request-wraper" style={{minHeight:"550px"}}>
			<button class="request-pop-close" onClick={()=>navigate('/groups')}>
				<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11 1L1 11M1 1L11 11" stroke="#7f7f7f" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"></path>
				</svg>
			</button>

      {loading ?( // Show loader if loading is true
                  <div className="loader-container"  style={{display:"flex",alignItems:"center",justifyContent:"center", minHeight:"550px"}}>
                    <Spin size="large" />
                  </div>
                ) :<>
        <div class="sen-request-content ctm-scroll">
				<div class="sen-request-row">
					<div class="sen-request-col">
						<span class="sen-request-title">How Many Requests</span>
						<div class="sen-requests" onChange={(e)=>setRequests(e.target.value) }>
						    <label class="sen-group-items" for="request-0">
						        <input type="radio" id="request-0" name="request" checked={requests === "5"} value="5" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">5</span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="request-1">
						        <input type="radio" id="request-1" name="request" checked={requests === "10"} value="10" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">10</span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="request-2">
						        <input type="radio" id="request-2" name="request" checked={requests === "20"} value="20" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">20</span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="request-3">
						        <input type="radio" id="request-3" name="request" checked={requests === "30"} value="30" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">30</span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="request-4">
						        <input type="radio" id="request-4" name="request" checked={requests === "40"} value="40" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">40</span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="request-5">
						        <input type="radio" id="request-5" name="request" checked={requests === "50"} value="50" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">50</span>
						        </div>
						    </label>
						    {/* <label class="sen-group-items" for="request-6">
						        <input type="radio" id="request-6" name="request" checked="" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">Custom</span>
						        </div>
						    </label> */}
						</div>
					</div>
					<div class="sen-request-col">
						<span class="sen-request-title">Interval</span>
						<div class="sen-interval-wraper"   onChange={(e)=>setTime(e.target.value) }>
						    <label class="sen-group-items" for="interval-0">
						        <input type="radio" id="interval-0" name="interval" checked={time === "1-3"} value="1-3" />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">
						                <span>
						                    <span>Medium</span> 1 to 3 Minutes </span>
						            </span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="interval-1">
						        <input type="radio" id="interval-1" name="interval" checked={time === "3-5"} value="3-5"  />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">
						                <span>
						                    <span>Slow</span> 3 to 5 Minutes </span>
						            </span>
						        </div>
						    </label>
						    <label class="sen-group-items" for="interval-2">
						        <input type="radio" id="interval-2" name="interval" checked={time === "5-10"} value="5-10"  />
						        <div class="sen-group-wraper-right">
						            <span class="group-name">
						                <span>
						                    <span>Very Slow</span> 5 to 10 Minutes </span>
						            </span>
						        </div>
						    </label>
						</div>
					</div>
					<div class="sen-request-col">
						<span class="sen-request-title">keywords</span>
						<div class="sen-keywords-cards">
							<div class="sen-keywords-box">
								<span>Negative keywords</span>
								<div class="sen-keywords-tagWrap" style={{display:"flex", gap:"10px"}}>
									{keyWords?.negative?.map((key,i)=><div>
                    <>
                    <span class="keywords-tag tag-red">
										<span>{key}</span>
										<button class="keywords-tag-close" style={{cursor:"pointer"}} onClick={()=>removeKeyWord(false,i)}>
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M13 1L1 13M1 1L13 13" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</button>
									</span>
                  </>
                  </div>

                  ) }
								</div>
								<input 
                type="text" 
                placeholder="Negative keywords" 
                value={currentNegative}
                onChange={(e)=>setCurrentNegative(e.target.value.trim())}
                onKeyDown={(e)=>handleKeyDown(e,false)}
                />
							</div>
							<div class="sen-keywords-box">
								<span>Positive keywords</span>
								<div class="sen-keywords-tagWrap" style={{display:"flex", gap:"10px"}}>
               { keyWords?.positive?.map((key,i)=><div>
                <span class="keywords-tag tag-green">
										<span>{key}</span>
										<button class="keywords-tag-close" style={{cursor:"pointer"}} onClick={()=>removeKeyWord(true,i)}>
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M13 1L1 13M1 1L13 13" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										</button>
									</span>
               </div>
                )}
								</div>
								<input
                  type="text"
                  onChange={(e)=>setCurrentPositive(e.target.value.trim())}
                  value={currentPositive}
                  onKeyDown={(e)=>handleKeyDown(e,true)}
                  placeholder="Positive keywords"
                  />
							</div>
						</div>
					</div>
				</div>
        <div class="ag-create-btn">
                  <button class="ag-lg-btn ag-bg-gray" onClick={()=>handleSubmit()}>
                    Create
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 5.24242V6.75758H9.84848L5.3346 10.9242L6.5 12L13 6L6.5 0L5.3346 1.07576L9.84848 5.24242H0Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </button>
        </div>
			</div>
      </>}
			
		</div>
    </>
  )
}

export default GroupSendRequest