// ResetPassword.js
import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPass } from '../../../../services/apiCalls';

const { Title, Text } = Typography;

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");// Retrieve the reset token from URL params
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
        // Call the backend API to send reset password email
        const response = await resetPass({ email, password: values.password });
  
        
        if (response?.data?.success) {
          
          message.success({
            content: 'Password changed successfully.',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
          });
          navigate('/login');
        } else {
          message.error({
            content: 'OOPS! Something went wrong',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
          })
        }
      } catch (error) {
  
        message.error({
          content: error?.response?.data?.message,
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
        })
      } finally {
        setLoading(false);
      }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card style={{ maxWidth: 400, width: '100%', padding: '20px' }}>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>Reset Password</Title>
        <Text type="secondary" style={{ display: 'block', marginBottom: '20px', textAlign: 'center' }}>
          Enter your new password below to reset it.
        </Text>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password size="large" placeholder="Enter your new password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              loading={loading}
              style={{ fontWeight: 'bold' }}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
        <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
          Remember your password? <a href="/login">Login</a>
        </Text>
      </Card>
    </div>
  );
};

export default ResetPassword;
