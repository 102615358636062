import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Popconfirm } from 'antd';
import "./users.css"
import { addNewUser, deleteUser, getUsersList, getUserToken, updateUser, USER_URL } from '../../../../services/apiCalls';
import { LockOutlined } from '@ant-design/icons';
import { message  } from 'antd';



const { Title } = Typography;

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [modalData, setModalData] = useState({})

  const showAddUserModal = () => {
    setEditingUser(null);
    setIsModalVisible(true);
  };

  const showEditUserModal = (record) => {
    setEditingUser(record);
    setIsModalVisible(true);
  };

  const handleDeleteUser = async(key) => {
    const params ={
      user_id: key
    }
    try {
      const response = await deleteUser(params);
      if (response?.status === 200) {
        message.success({
          content: 'User deleted successfully',
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
          
        })
        allUsersList()
      }
    } catch (error) {
      
    }
 
  };

  const handleOk =async (values) => {
   
    if (editingUser) {
      try {
        let params = {
          "first_name": values?.first_name,
          "last_name": values?.last_name,
          "email": values?.email,
          "password": values?.password,
          "user_id": editingUser?.id
        }
        const response = await updateUser(params);
        if (response?.status === 200) {
          
          message.success({
            content: 'User edited successfully',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
          })
        setIsModalVisible(false);
        setEditingUser(null);
        
          allUsersList();
        }
        
      } catch (error) {
        
      }
     
    } else {
      try {
        let params = {
          "first_name": values?.first_name,
          "last_name": values?.last_name,
          "email": values?.email,
          "password": values?.password
        }
        const response = await addNewUser(params);
        if (response?.status === 200) {
          
          message.success({
            content: 'User added successfully',
            style: {
              position: 'absolute',
              right: '0px',
              top: '0px',
            }
          })
         setIsModalVisible(false);
         setEditingUser(null);
         
          allUsersList();
        }
        
      } catch (error) {
        
      }

      
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingUser(null);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
  };

  const filteredUsers = users?.filter(user =>
    Object.keys(user).some(key =>
      user[key]?.toString()?.toLowerCase()?.includes(searchText)
    )
  );

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      onCell: (record) => ({
        style: { cursor: 'pointer' },
        onClick: () => {
          console.log(record)
          setModalData(record)
          setIsModalEdit(true)},  // Custom onClick function
      }),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
 
    // {
    //   title: 'Phone Number',
    //   dataIndex: 'phone',
    //   key: 'phone',
    // },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    // },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => a.role - b.role,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => showEditUserModal(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDeleteUser(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  const allUsersList = async ()=>{
    try {
      const response =  await getUsersList();
      setUsers(response?.data?.data)
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  
  }
const userToken = async () => {
  try {
    const response =  await getUserToken({user_id:`${modalData.id}`});
    if (response?.status === 200) {
      window.open(`${USER_URL}?token=${response?.data?.data?.token}`, '_blank');

    }
  } catch (error) {
    console.error('Error fetching users:', error);
  }
}
  

  useEffect(() => {
    allUsersList()
  }, [])
  

  return (
    <div className='users_page'>
      <div className='users-title'>
        <Title level={1}>Users</Title>
        <Button type="primary" onClick={showAddUserModal} style={{ marginBottom: 16, marginRight: 16 }}>
          Add User
        </Button>
      </div>

      <div className='users-Search'>
      <Input
          placeholder="Search users"
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </div>

      <Table
        columns={columns}
        dataSource={filteredUsers}
        pagination={{ pageSize: 5 }}
        rowKey="key"
      />
      <Modal
        title={editingUser?.id ? 'Edit User' : 'Add User'}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <UserForm
          initialValues={ editingUser || {first_name: '', last_name:'', email: ''}}
          onCancel={handleCancel}
          onFinish={handleOk}
          isEdit={editingUser}
        />
      </Modal>

      <Modal
        title={"User Settings"}
        open={isModalEdit}
        onCancel={()=>setIsModalEdit(false)}
        footer={null}
      >
      <Button onClick={()=>userToken()}>Login</Button>
       
      </Modal>
    </div>
  );
};

const UserForm = ({ initialValues, onCancel, onFinish, isEdit }) => {
  const [form] = Form.useForm();

  // If initialValues change dynamically, update the form values
  React.useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues} // This will populate the form fields
      onFinish={onFinish}
    >
      <Form.Item
        name="first_name"
        label="First Name"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="last_name"
        label="Last Name"
        rules={[{ required: true, message: 'Please enter the name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter the email' },
          { type: 'email', message: 'This should be a valid email' }
        ]}
      >
        <Input />
      </Form.Item>

     
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please enter your password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            size="large"
          />
        </Form.Item>
   

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
          {isEdit?.id ? 'Save Changes' : 'Add User'}
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Form.Item>
    </Form>
  );
};


export default UsersPage;
