import React, { useState, useEffect } from 'react';
import { Table, Select, List, Spin, message,Button } from 'antd';
import { getCategoryList, getKeywordsList, getKeywordsResponseList } from '../../../../services/apiCalls';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

const { Option } = Select;

const CascadingSelection = () => {
  const [categories, setCategories] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [pagination, setPagination] = useState({
    "page": "1",
    "limit": "10"
  })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const catId = searchParams.get("id");

  // Fetch categories on mount
  useEffect(() => {
   if (catId) {
    handleCategorySelect(catId)
   }else{
    fetchCategories(pagination);
     
   }
  }, [catId]);
  

  const fetchCategories = async (params) => {
    try {
      const response = await getCategoryList(params)
      setCategories(response?.data?.data);
    } catch (error) {
      message.error('Failed to load categories.');
    }
  };

  // Fetch keywords when a category is selected
  const handleCategorySelect = async (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedKeyword(null); // Reset keyword selection
    setResponses([]); // Reset responses
    setLoading(true);
   let params = {
   
        "category_id": `${categoryId}`
     
   }
    try {
      const response = await getKeywordsList(params);
      setKeywords(response?.data?.data);
    } catch (error) {
      message.error('Failed to load keywords.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch responses when a keyword is selected
  const handleKeywordSelect = async (keywordId) => {
    setSelectedKeyword(keywordId);
    setLoading(true);
    let params = 
    {
        "category_id": `${selectedCategory}`,
        "keyword_id": `${keywordId}`
      }
    try {
      const response = await getKeywordsResponseList(params);
      setResponses(response.data.data);
    } catch (error) {
      message.error('Failed to load responses.');
    } finally {
      setLoading(false);
    }
  };

  // Table columns for categories
  const categoryColumns = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },

    {
      title: 'Action',
     
      key: 'createdAt',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() =>navigate(`/category?id=${record.id}`) }>
          <EyeOutlined style={{ fontSize: '24px', color: '#1890ff', marginRight: '20px' }} />
          </Button>
        </span>
      ),
    },
  ];


  const responseColumns = [
    {
      title: 'Response Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];

  return (

    <>

    {
      !catId ? <div>
      <h3>Select Category</h3>
      <Table
        columns={categoryColumns}
        dataSource={categories}
        rowKey="id"
        pagination={false}
        rowClassName={(record) => (record.id === selectedCategory ? 'table-row-selected' : '')}
      />
    </div>:<>
  
        <>
          <h3>Select Keyword</h3>
          <Select
            placeholder="Select a keyword"
            onChange={handleKeywordSelect}
            style={{ width: '100%', marginBottom: 16 }}
            disabled={loading || keywords.length === 0}
          >
            {keywords.map((keyword) => (
              <Option key={keyword.id} value={keyword.id}>
                {keyword.name}
              </Option>
            ))}
          </Select>
        </>
     

      {loading && <Spin />}

      {selectedKeyword && responses.length > 0 && (
        <>
          <h3>Responses</h3>
          {/* <List
            bordered
            dataSource={responses}
            renderItem={(item) => <List.Item>{item.name}</List.Item>}
          /> */}

        <Table
        columns={responseColumns}
        dataSource={responses}
        rowKey="id"
        pagination={false}
      />
        </>
      )}

    </>


    }
      


      
    </>
  
  );
};

export default CascadingSelection;
