import React, { useEffect } from 'react'
import AdminRoutes from './admin'
import UserRoutes from './user'
import { useLocation } from 'react-router-dom';


const Routes = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      window.location.href = '/'; // Redirect to dashboard after login
      return;
    }
 
  }, [token])
  const url =  window.location.host?.split(".")?.[0]
  return (
   <>
    { url !== "admin" ?<UserRoutes/> : <AdminRoutes/>}
   </>
  )
}

export default Routes