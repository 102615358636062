import React from 'react'
import { Routes, Route } from "react-router-dom";
import LoginPage from '../../pages/user/auth/loginPage';
import ForgotPassword from '../../pages/user/auth/forgotPass';
import ResetPassword from '../../pages/user/auth/changePass';

const UserAuthRotes = () => {
  return (

   <>
    <Routes>
        <Route path="/login" element={<LoginPage />}/>
        <Route path="/forgotPassword" element={<ForgotPassword />}/>
        <Route path="/resetPassword" element={<ResetPassword />}/>
        <Route path="*"  element={<LoginPage />} />
    </Routes>
   </>
   
  )
}

export default UserAuthRotes