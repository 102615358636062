import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Routes from './app/routes/routes';

function App() {
  return (
   <>
    <BrowserRouter>
     <Routes/>
    </BrowserRouter>
  
   </>
  );
}

export default App;
