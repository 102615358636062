import React, { useState } from 'react';
import { Layout, Menu, Button, Row, Col, Modal } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DashboardOutlined,
  AppstoreOutlined 
} from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import './layout.css'; // Optional: Create a CSS file for additional styling
import connection from "../../../assets/images/connection.svg"
import Dashboard from "../../../assets/images/dashboard.svg"
import followes from "../../../assets/images/followers.svg"
import friend from "../../../assets/images/friend.svg"
import keyword from "../../../assets/images/keywords.svg"
import group from "../../../assets/images/my-group.svg"

const { Header, Sider, Content } = Layout;

const UserLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    Modal.confirm({
      title: 'Confirm Logout',
      content: 'Are you sure you want to log out?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        // Handle logout logic here
        localStorage.removeItem('token');
        window.location.href = '/'; // Redirect to login page after logout
      },
    });
  };

  const menuItems = [
    {
      key: '/',
      icon:   <img src={Dashboard} alt="dashboard" style={{ width: "16px", marginRight: "8px" }} />,
      label: <Link to="/">Dashboard</Link>,
    },
    {
      key: '/groups',
      icon:  <img src={group} alt="group" style={{ width: "16px", marginRight: "8px" }} />,
      label: <Link to="/groups">My Groups</Link>,
    }
    ,
    {
      key: '/keywords',
      icon:  <img src={keyword} alt="keyword" style={{ width: "16px", marginRight: "8px" }} />,
      label: <Link to="/keywords">My Keywords</Link>,
    }
    ,
    {
      key: '/friends',
      icon:  <img src={friend} alt="friend" style={{ width: "16px", marginRight: "8px" }} />,
      label: <Link to="/friends">My Friends</Link>,
    }
    ,
    {
      key: '/followers',
      icon: <img src={followes} alt="followes" style={{ width: "16px", marginRight: "8px" }} />,
      label: <Link to="/followers">My Followers</Link>,
    }
    ,
    {
      key: '/connections' ,
      icon: <img src={connection} alt="connection" style={{ width: "16px", marginRight: "8px" }} />,
      label: <Link to="/connections">My Connections</Link>,
    }
  ];

  return (
    <Layout className="ctm-main-app" style={{ minHeight: '100vh' }}>
      <Sider className="ctm-main-sidebar" trigger={null} collapsible collapsed={collapsed}>
        <div className="logo"><h1 style={{color:"white"}}>{!collapsed? "User": "U"}</h1></div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]} // Use selectedKeys instead of defaultSelectedKeys
          items={menuItems}
        />
      </Sider>
      <Layout className="site-layout ctm-main-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger ctm-trigger',
                  onClick: toggle,
                }
              )}
            </Col>
            <Col>
              <Button className='user-profile-btn' onClick={handleLogout} type="primary" style={{ marginRight: 16 }}>
                <div href="#!" class="user-profile">
									<div className='user-Img'>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="18" cy="18" r="18" fill="#CECECE"/>
                        <path d="M15 13.875C15 14.4717 14.7629 15.044 14.341 15.466C13.919 15.8879 13.3467 16.125 12.75 16.125C12.1533 16.125 11.581 15.8879 11.159 15.466C10.7371 15.044 10.5 14.4717 10.5 13.875C10.5 13.2783 10.7371 12.706 11.159 12.284C11.581 11.8621 12.1533 11.625 12.75 11.625C13.3467 11.625 13.919 11.8621 14.341 12.284C14.7629 12.706 15 13.2783 15 13.875Z" fill="white"/>
                        <path d="M9 7.125C8.20435 7.125 7.44129 7.44107 6.87868 8.00368C6.31607 8.56629 6 9.32935 6 10.125V25.125C6 25.9206 6.31607 26.6837 6.87868 27.2463C7.44129 27.8089 8.20435 28.125 9 28.125H27C27.7956 28.125 28.5587 27.8089 29.1213 27.2463C29.6839 26.6837 30 25.9206 30 25.125V10.125C30 9.32935 29.6839 8.56629 29.1213 8.00368C28.5587 7.44107 27.7956 7.125 27 7.125H9ZM27 8.625C27.3978 8.625 27.7794 8.78304 28.0607 9.06434C28.342 9.34564 28.5 9.72718 28.5 10.125V19.875L22.8345 16.9545C22.6938 16.884 22.5346 16.8596 22.3792 16.8846C22.2239 16.9097 22.0804 16.9829 21.969 17.094L16.404 22.659L12.414 20.001C12.2699 19.9051 12.0971 19.862 11.9249 19.8789C11.7527 19.8959 11.5916 19.9718 11.469 20.094L7.5 23.625V10.125C7.5 9.72718 7.65804 9.34564 7.93934 9.06434C8.22064 8.78304 8.60218 8.625 9 8.625H27Z" fill="white"/>
                    </svg>
                  </div>
									<span class="user-name-wraper">
										<span class="user-name">John Doe</span>
										<span class="user-auth">Admin</span>
									</span>
									<span class="user-drop-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
											<path d="M11 1L6 6L1 1" stroke="#646A70" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										</svg>
									</span>
								</div>
              </Button>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: '#fff',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserLayout;
