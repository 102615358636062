import React from 'react'
import CascadingSelection from '../../../components/admin/cascadingSelection/cascadingSelection';

const Category = () => {
  return (
    <>
     <CascadingSelection/>
    </>
  )
}

export default Category;