import React, { useState } from 'react';
import { Layout, Menu, Button, Row, Col, Modal } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DashboardOutlined,
  AppstoreOutlined 
} from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import './layout.css'; // Optional: Create a CSS file for additional styling

const { Header, Sider, Content } = Layout;

const AdminLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    Modal.confirm({
      title: 'Confirm Logout',
      content: 'Are you sure you want to log out?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        // Handle logout logic here
        localStorage.removeItem('token');
        window.location.href = '/'; // Redirect to login page after logout
      },
    });
  };

  const menuItems = [
    {
      key: '/',
      icon: <DashboardOutlined />,
      label: <Link to="/">Dashboard</Link>,
    },
    {
      key: '/users',
      icon: <UserOutlined />,
      label: <Link to="/users">Users</Link>,
    },
    {
      key: '/category',
      icon: <AppstoreOutlined  />,
      label: <Link to="/category">Category</Link>,
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo"><h1 style={{color:"white"}}>{!collapsed? "Sentinal": "S"}</h1></div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]} // Use selectedKeys instead of defaultSelectedKeys
          items={menuItems}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: toggle,
                }
              )}
            </Col>
            <Col>
              <Button onClick={handleLogout} type="primary" style={{ marginRight: 16 }}>
                Logout
              </Button>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: '#fff',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
