import React from 'react'
import { Routes, Route } from "react-router-dom";
import LoginPage from '../../pages/admin/auth/loginPage';
import SignUpPage from '../../pages/admin/auth/signup';
import ForgotPassword from '../../pages/admin/auth/forgotPass';
import ResetPassword from '../../pages/admin/auth/changePass';

const AdminAuthRotes = () => {
  return (

   <>
    <Routes>
        <Route path="/login" element={<LoginPage />}/>
        <Route path="/signup" element={<SignUpPage />}/>
        <Route path="/forgotPassword" element={<ForgotPassword />}/>
        <Route path="/resetPassword" element={<ResetPassword />}/>
        <Route path="*"  element={<LoginPage />} />
    </Routes>
   </>
   
  )
}

export default AdminAuthRotes