import axios from "axios";

export const  API_URL = 'https://api.thesentinel.app/admin'
export const  API_URL_USER = 'https://api.thesentinel.app/v1/api'
// export const  USER_URL = 'http://localhost:3000'
export const  USER_URL = 'https://thesentinel.app'


function updateAuthorizationHeader() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
  }

  export async function accountLogin (params) {
    return await axios.post(API_URL + "/auth/login", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  
  export async function forgotPass (params) {
    return await axios.post(API_URL + "/auth/forgotPass", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function resetPass (params) {
    return await axios.post(API_URL + "/auth/resetPass", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  

  export async function getUsersList () {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/users/get-user-listing", {})
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function addNewUser (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/users/add-new-user", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function deleteUser (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/users/delete", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function updateUser (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL + "/users/update-details", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }


  export async function getUserToken (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL_USER + "/user/generate-token", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }


  export async function getCategoryList (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL +"/category/category-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function getKeywordsList (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL +"/category/keywords-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }

  export async function getKeywordsResponseList (params) {
    updateAuthorizationHeader()
    return await axios.post(API_URL +"/category/keyword-response-listing", params)
      .then(response => {
        return response; // Adjust based on your response structure
      })
      .catch(error => {
        return error; // Re-throw the error so it can be handled with async/await
      });
  }