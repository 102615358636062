import React,{useEffect, useRef, useState} from "react";
import "./groupComp.css";
import { Flex, Tag , message,Spin} from "antd";
import { addGroupResp, getGroupResp } from "../../../../services/userApiCalls"; 
import { useNavigate } from "react-router-dom";


const GroupMessage = ({grpId}) => {
    const [keyWords,setKeyWords]=useState({
        negative:[],
        positive:[]
    })
    const [currentNegative, setCurrentNegative] = useState('');
    const [currentPositive, setCurrentPositive] = useState('');
    const [type, setType] = useState("add");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [response, setResponse] = useState(['','','','']);
    const scrollRef = useRef(null);
    const navigate = useNavigate()
    
   const  handleKeyDown = (target,isPos)=>{
    if(target.keyCode === 13){
        if (isPos) {
            currentPositive &&   setKeyWords((prev)=>({...prev,positive:[...prev.positive,currentPositive]}))
            setCurrentPositive('')
        }else{
            currentNegative &&    setKeyWords((prev)=>({...prev,negative:[...prev.negative,currentNegative]}))
            setCurrentNegative('')
        }
       
    }
   }


   const removeKeyWord = (isPos,num)=>{
    if(isPos){
        setKeyWords((prev)=>({...prev,positive:prev.positive.filter((_,indx)=>indx!==num)}))
    }else{
        setKeyWords((prev)=>({...prev,negative:prev.negative.filter((_,indx)=>indx!==num)}))
    }
   }
  

   

   const handleRespChange=(index,item)=>{
    const updatedResponse = [...response];
    updatedResponse[index] = item;
    setResponse(updatedResponse);
   }
   const clearStates = ()=>{
    setResponse(['','','',''])
    setTitle('')
    setKeyWords({
      negative:[],
      positive:[]
  })
  }

   const handleSubmit=()=>{
    setLoading(true)
    const params ={
      title: title,
      negative_keywords: keyWords.negative,
      positive_keywords: keyWords.positive,
      response: response.filter((item)=> item && item),
      type,
      group_id:`${grpId}`
  }

   if(!title || keyWords.negative.length === 0 || keyWords.positive.length === 0 || response.filter((item)=> item && item).length === 0){
    message.error({
        content:"Please fill all the required fields",
        style: {
          position: 'absolute',
          right: '0px',
          top: '0px',
        }
      })
    return;
   }

 
    addGroupResp(params).then((res)=>{
      setLoading(false)
      navigate('/groups')
        message.success({
          content:"Response Added successfully",
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
        })
        clearStates()
    }).catch((err)=>{
      message.error({
        content:"oops something went wrong",
        style: {
          position: 'absolute',
          right: '0px',
          top: '0px',
        }
      })
        console.log(err)
        setLoading(false)
    })
   }

   const fetchData = ()=>{
    setLoading(true)
    getGroupResp({group_id:`${grpId}`}).then((res)=>{
      if (res?.data?.data) {
        let responseData =[]
        for (let index = 0; index < 4; index++) {
          if (res?.data?.data?.response?.[index]) {
            responseData.push(res?.data?.data?.response?.[index])
          } else {
            responseData.push('')
          }
          
        }

        setResponse(responseData)
         
         setTitle(res?.data?.data?.title)
         setKeyWords({
           negative:res?.data?.data?.negative_keywords,
           positive:res?.data?.data?.positive_keywords
         })
        setType("update")
      }else{
        setType("add")
      }
      setLoading(false)
     
    }).catch((err)=>{
      setLoading(false)
    })
     
   }

   useEffect(() => {
    if (grpId) {
      fetchData()
    }
   return ()=>{
      setKeyWords({negative:[],
        positive:[]})
      setResponse(['','','',''])
      setTitle('')
      setType("add")

    }
   }, [grpId])
   
  return (
    <>
      <div class="ag-msg-popup" style={{minHeight:"550px"}}>
        <div class="ag-msg-popup-inner ag-scroll">
          <div class="ag-msg-popup-content">
            <div class="ag-create-msg-main ag-msg-step-03">
              <div class="ag-create-msg-inner">
              {loading ? ( // Show loader if loading is true
                  <div className="loader-container">
                    <Spin size="large" />
                  </div>
                ):
                <>
                <div>
                  <div class="ag-main-title">Create new message</div>
                  <div class="a-msg-close-btn">
                    <button class="version-pop-close-btn" onClick={()=>navigate('/groups')}>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 1L1 11M1 1L11 11"
                          stroke="#949494"
                          stroke-width="1.7"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div style={{margin:"20px 15px 20px 0px"}}>
                    <input type="text" className="ag-message-title" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder="Title..."/>
                </div>
                <div class="ag-create-msg-wraper">
             
                  <div class="ag-create-msg-wraper-left">
                    <div class="ag-create-msg-innerMain">
                      <div class="ag-create-leftInnerStart">
                        <div class="ag-create-textarea-wraper">
                          <div class="ag-create-textarea-scroll" ref={scrollRef}>
                          {
                           (response?.length?response:['','','','']  ).map((data,i)=>{
                                return  <div class="ag-create-textarea" key={i} >
                              <div class="ag-create-textarea-items">

                                <textarea placeholder="Write your text..." value={data}  onChange={(e)=> handleRespChange(
                                        i,
                                        e.target.value
                                      )} />
                              </div>
                            </div>
                            })
                          }
                           
                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ag-create-msg-wraper-right">
                    <div class="keywords-wraper">
                      <span>Negative keywords</span>
                      <Flex gap="4px 0" wrap>
                      {keyWords.negative?.map((words,indx)=>{
                        return <Tag
                          color="error"
                          style={{display:"flex",gap:"10px"}}
                          key={`${words}-${indx}`}
                        >
                          <span>{words}</span>
                          <span style={{cursor:"pointer"}} onClick={()=>removeKeyWord(false,indx)}>x</span>
                        </Tag>
                      })}
                       
                      </Flex>
                      <input
                        type="text"
                        placeholder="Negative keywords"
                        value={currentNegative}
                        onChange={(e)=>setCurrentNegative(e.target.value.trim())}
                        onKeyDown={(e)=>handleKeyDown(e,false)}
                      />
                    </div>
                    <div class="keywords-wraper">
                      <span>Positive keywords</span>
                      <Flex gap="4px 0" wrap>
                      {keyWords.positive?.map((words,indx)=>{
                        return <Tag
                          color="success"
                          style={{display:"flex",gap:"10px"}}
                          key={`${words}-${indx}`}
                        >
                          <span>{words}</span>
                          <span style={{cursor:"pointer"}} onClick={()=>removeKeyWord(true,indx)}>x</span>
                        </Tag>
                      })}
                       
                      </Flex>
                      <input
                        type="text"
                        onChange={(e)=>setCurrentPositive(e.target.value.trim())}
                        value={currentPositive}
                        onKeyDown={(e)=>handleKeyDown(e,true)}
                        placeholder="Positive keywords"
                      />
                    </div>
                  </div>
                </div>
                <div class="ag-create-btn">
                  <button class="ag-lg-btn ag-bg-gray" onClick={()=>handleSubmit()}>
                    Create
                    <svg
                      width="13"
                      height="12"
                      viewBox="0 0 13 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 5.24242V6.75758H9.84848L5.3346 10.9242L6.5 12L13 6L6.5 0L5.3346 1.07576L9.84848 5.24242H0Z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </button>
                </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupMessage;
