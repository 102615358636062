import React from 'react'
import { Routes, Route } from "react-router-dom";
  import UsersPage from '../../pages/admin/Users/Users';
import Dashboard from '../../pages/admin/DashBoard/Dashboard';
import AdminLayout from '../../layout/admin/AdminLayout';
import Category from '../../pages/admin/category/category';


const AdminPrivateRoutes = () => {
  return (

   <>
   <AdminLayout>
    <Routes>
        <Route path="/" element={<Dashboard/>}/>
        <Route path="/users" element={<UsersPage />}/>
        <Route path="/category" element={<Category />}/>
        <Route path="*" element={<Dashboard/>}/>

    </Routes>
    </AdminLayout>
   </>
   
  )
}

export default AdminPrivateRoutes